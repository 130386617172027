@import url('https://fonts.googleapis.com/css?family=Play');

html,
body,
#root {
  height: 100% !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  color: white;
  font-size: 14px;
  line-height: unset;
  font-family: 'Play', sans-serif !important;
}

body,
html {
  position: absolute;
  overflow: hidden;
}

svg {
  vertical-align: middle;
}

/* For iOS Safari */
input {
  border-radius: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
